var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper md:min-h-screen lg:flex"},[(_vm.currentUser && _vm.$route.path !== '/setup')?_c('div',[_c('div',{staticClass:"lg:h-screen lg:overflow-y-auto lg:sticky lg:top-0 lg:w-241 without-scrollbar"},[_c('hypefury-header',{attrs:{"userFollowers":_vm.user.followers}}),_c('side-bar',{attrs:{"gumroadRevenue":_vm.gumroadRevenue,"currencySymbol":_vm.gumroadCurrency,"userName":_vm.user.name,"userFollowers":_vm.user.followers,"userPhoto":_vm.user.photoURL,"setupProgress":_vm.setupProgress},on:{"showSetupProgress":_vm.showSetupProgressPopup}},[_c('template',{slot:"links"},[(_vm.userProfile.managers.length > 0)?_c('sidebar-item',{attrs:{"link":{
              name: 'Ghostwriting Validation',
              icon: '/img/icons/add.svg',
              path: '/ghostwriting/validation',
            },"classes":{ 'text-red-500': _vm.clientGhostwritingThreads && _vm.clientGhostwritingThreads.length > 0 }}}):_vm._e(),_c('button',{staticClass:"custom-menu-button cursor-pointer gap-1 rounded-full px-4 py-1 text-left text-base font-medium",attrs:{"data-cy":"sidebar-create-button"},on:{"click":_vm.handleSidebarCreateButton}},[_vm._v(" Create ")]),_c('sidebar-item',{attrs:{"link":{
              name: 'Dashboard',
              counterTooltip: 'Dashboard',
              path: '/dashboard',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Queue',
              counterTooltip: 'Retweets are not taken into account.',
              counter: _vm.currentlyScheduledPostsCount,
              icon: '/img/icons/queue.svg',
              path: '/queue'
            }}}),_c('div',{staticClass:"sidebar-menu-item"},[_c('new-button',{staticClass:"app-sidebar-link inline-flex items-center justify-center gap-1 rounded-full px-4 font-medium",attrs:{"type":"transparent","data-cy":"sidebar-drafts-button"},on:{"click":_vm.openNewComposerWithDrafts}},[_c('span',{staticClass:"text-base font-medium"},[_vm._v("Drafts")])])],1),_c('div',{staticClass:"sidebar-menu-item"},[_c('new-button',{staticClass:"app-sidebar-link inline-flex items-center justify-center gap-1 rounded-full px-4 font-medium",attrs:{"type":"transparent","data-cy":"sidebar-prompts-button"},on:{"click":_vm.openNewComposerWithPrompts}},[_c('span',{staticClass:"text-base font-medium"},[_vm._v("Inspiration")])])],1),_c('sidebar-item',{attrs:{"link":{
              name: 'Recurrent Posts',
              icon: '/img/icons/calendar.svg',
              path: '/recurrent-posts'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Powerups',
              icon: '/img/icons/queue.svg',
              path: '/powerups'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Youtube to Tweet',
              icon: '/img/icons/queue.svg',
              path: '/youtube-to-tweet'
            }}}),(_vm.isWriter(_vm.userProfile))?_c('sidebar-item',{attrs:{"link":{
              name: 'Growth Program - Write',
              icon: '/img/icons/growth.svg',
              path: '/growth/write'
            }}}):_vm._e(),(_vm.isInfluencer(_vm.userProfile))?_c('sidebar-item',{attrs:{"link":{
              name: 'Growth Program - Check',
              icon: '/img/icons/growth.svg',
              path: '/growth/check'
            }}}):_vm._e(),(_vm.userProfile.isAdmin)?_c('sidebar-item',{attrs:{"link":{
              name: 'Growth Program - Admin',
              icon: '/img/icons/growth.svg',
              path: '/growth/admin'
            }}}):_vm._e()],1),_c('template',{slot:"earn-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Auto-DMs',
              icon: '/img/icons/queue.svg',
              path: '/auto-dms',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Gumroad Sales',
              icon: '/img/icons/money.svg',
              path: '/gumroad-sales',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Time-based Sales',
              icon: '/img/icons/money.svg',
              path: '/toolbox/time-based-sales'
            }}})],1),_c('template',{slot:"engage-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Engagement Builder',
              icon: '/img/icons/all.svg',
              path: '/feed',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'My Mentions',
              icon: '/img/icons/mentions.svg',
              path: '/mentions',
            }}})],1),_c('template',{slot:"ghostwriting-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Dashboard',
              icon: '/img/icons/add.svg',
              path: '/ghostwriting/dashboard',
            }}}),_vm._l((_vm.userProfile.managedAccounts),function(managedAccount){return _c('sidebar-item',{key:managedAccount.uid,attrs:{"link":{
              name: `@${managedAccount.profileInfo.screen_name}`,
              counter: _vm.managedAccountsAdditionalData[managedAccount.uid]
                ? _vm.managedAccountsAdditionalData[managedAccount.uid].numberOfScheduledPosts
                : 0,
              icon: '',
              path: `/ghostwriting/client/${managedAccount.profileInfo.screen_name}`,
            }}})})],2),_c('template',{slot:"track-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Analytics',
              icon: '/img/icons/analytics.svg',
              path: `/analytics/${_vm.userProfile.username}`,
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'History',
              icon: '/img/icons/history.svg',
              path: '/history'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Evergreen Posts',
              icon: '/img/icons/star-empty.svg',
              path: '/evergreen-posts'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Autoplugs Metrics',
              icon: '/img/icons/autoplug.svg',
              path: '/autoplugs-metrics',
            }}})],1),_c('template',{slot:"dm-crm-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Campaigns',
              icon: '/img/icons/autodm.svg',
              path: '/auto-dm/campaigns',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Users',
              icon: '/img/icons/followers.svg',
              path: '/auto-dm/users',
            }}})],1),_c('template',{slot:"ghostwriting-clients"},[(_vm.userProfile.isGhostwriter)?_c('sidebar-item',{attrs:{"link":{
              name: 'Dashboard',
              icon: '/img/icons/add.svg',
              path: '/ghostwriting/dashboard',
            }}}):_vm._e(),_vm._l((_vm.userProfile.managedAccounts),function(managedAccount){return _c('sidebar-item',{key:managedAccount.uid,attrs:{"link":{
              name: `@${managedAccount.profileInfo.screen_name}`,
              counter: _vm.managedAccountsAdditionalData[managedAccount.uid]
                ? _vm.managedAccountsAdditionalData[managedAccount.uid].numberOfScheduledPosts
                : 0,
              icon: '',
              path: `/ghostwriting/client/${managedAccount.profileInfo.screen_name}`,
            }}})})],2),_c('template',{slot:"toolbox-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Gumroad Sales',
              icon: '/img/icons/money.svg',
              path: '/gumroad-sales',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Time-based Sales',
              icon: '/img/icons/money.svg',
              path: '/toolbox/time-based-sales'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Auto-Retweeter',
              icon: '/img/icons/retweet.svg',
              path: '/toolbox/auto-retweeter'
            }}}),(!_vm.isMobile)?_c('sidebar-item',{attrs:{"link":{
              name: 'Archive Uploader',
              icon: '/img/icons/upload.svg',
              path: '/toolbox/twitter-archive-uploader',
            }}}):_vm._e()],1),_c('template',{slot:"account-links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Settings',
              icon: '/img/icons/settings.svg',
              path: '/settings'
            }}}),(_vm.userProfile.isAdmin)?_c('sidebar-item',{attrs:{"link":{
              name: 'Cancellations',
              icon: '/img/icons/settings.svg',
              path: '/cancellations'
            }}}):_vm._e(),(!_vm.isUserManaging)?_c('sidebar-item',{attrs:{"link":{
              name: 'Billing',
              icon: '/img/icons/billing.svg',
              path: '/billing'
            }}}):_vm._e()],1)],2)],1)]):_vm._e(),_c('notifications'),_c('div',{staticClass:"main-content lg:flex-1 border-l border-gray-90"},[_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('keep-alive',{attrs:{"include":"twitter-archive-uploader"}},[_c('div',[(_vm.showUpgradeAlert && _vm.$route.path !== '/create')?_c('base-alert',{staticClass:"mx-6 my-4 flex max-w-4xl items-center justify-between text-base md:mx-auto",attrs:{"dismissible":true,"type":"info","radius":"rounded-lg"},on:{"dismiss":_vm.hideAnnualUpgradeAlert}},[_c('div',{staticClass:"flex flex-col items-center gap-x-10 gap-y-6 sm:flex-row"},[_c('div',[_c('b',{staticClass:"text-ds-text-primary"},[_vm._v("Save 20% by switching to yearly billing! ")]),_c('span',{staticClass:"text-ds-text-primary"},[_vm._v("- Immunity to price hikes - 2 months for free")])]),(_vm.$route.path !== '/billing')?_c('router-link',{staticClass:"button custom-button small-button",staticStyle:{"text-decoration":"none !important"},attrs:{"to":"/billing?upgradeToYearly=true"}},[_vm._v(" Upgrade ")]):_vm._e()],1)]):_vm._e(),_c('router-view',{class:[
                'mx-auto',
                {
                  'max-w-610 px-4 pb-8 lg:px-0 lg:py-12':
                    _vm.$route.meta.layout === _vm.$options.LAYOUTS.DEFAULT,
                  'container px-4 pb-8 lg:px-16 lg:py-12':
                    _vm.$route.meta.layout === _vm.$options.LAYOUTS.LARGE,
                  'px-4 pb-8 lg:max-w-dashboard lg:px-20 lg:py-8 2xl:px-0':
                    _vm.$route.meta.layout === _vm.$options.LAYOUTS.DASHBOARD,
                },
              ]})],1)])],1)],1)]),(_vm.showSetupProgress)?_c('div',{staticClass:"fixed inset-0 z-20 flex items-center justify-center px-4 py-10"},[_c('div',{staticClass:"bg-white w-466 rounded-3xl py-12 px-8 relative z-30 app-create-modal"},[_c('div',{staticClass:"flex gap-x-2 items-center mb-6"},[_c('CircularProgress',{attrs:{"value":_vm.setupProgress,"strokeColor":"#28AF87","radius":24},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.setupProgress))])]},proxy:true}],null,false,3696055956)}),_c('span',{staticClass:"font-bold text-2xl"},[_vm._v("Set up your account")]),_c('button',{staticClass:"ml-auto",on:{"click":function($event){_vm.showSetupProgress = false;
            _vm.$eventStore.general.sidebar('Setup progress: Close');}}},[_c('inline-svg',{attrs:{"src":"/img/icons/close.svg"}})],1)],1),_c('p',{staticClass:"text-gray-20 app-modal-text text-lg mb-6"},[_vm._v("Maximize your social media growth by getting your Hypefury account set up properly.")]),_c('ul',{staticClass:"grid gap-4 list-none m-0 mb-6"},[_c('li',{staticClass:"flex items-center",attrs:{"data-cy":"setup-first-tweet"}},[_c('div',{staticClass:"checkbox"},[_c('input',{staticClass:"hidden",attrs:{"id":"scheduleThread","type":"checkbox","disabled":"true"},domProps:{"checked":_vm.getTask('scheduleThread.isCompleted')}}),_c('label',{attrs:{"for":"scheduleThread"},on:{"click":function($event){_vm.showComposer = true;
                _vm.showSetupProgress = false;
                _vm.$eventStore.general.sidebar('Setup progress: Schedule first tweet');}}},[_vm._v(" Schedule your first tweet ")])])]),_c('li',{staticClass:"flex items-center",attrs:{"data-cy":"setup-publish-tweet"}},[_c('div',{staticClass:"checkbox"},[_c('input',{staticClass:"hidden",attrs:{"id":"scheduleTenThreads","type":"checkbox","disabled":"true"},domProps:{"checked":_vm.userProfile.totalScheduledPosts >= 10}}),_c('label',{attrs:{"for":"scheduleTenThreads"},on:{"click":function($event){_vm.showComposer = true;
                _vm.showSetupProgress = false;
                _vm.$eventStore.general.sidebar('Setup progress: Publish 10 tweets');}}},[_vm._v(" Publish 10 tweets ")])])]),_c('li',{staticClass:"flex items-center",attrs:{"data-cy":"setup-use-powerapp"}},[_c('div',{staticClass:"checkbox"},[_c('input',{staticClass:"hidden",attrs:{"id":"powerUp","type":"checkbox","disabled":"true"},domProps:{"checked":_vm.getTask('enableTweetBooster.isCompleted') || _vm.getTask('setAutoplugTweet.isCompleted')}}),_c('label',{attrs:{"for":"powerUp"},on:{"click":function($event){_vm.$router.push('/powerups');
                _vm.showSetupProgress = false;
                _vm.$eventStore.general.sidebar('Setup progress: Use your first Powerup');}}},[_vm._v(" Use your first Power Up ")])])]),_c('li',{staticClass:"flex items-center justify-between",attrs:{"data-cy":"setup-enable-notifications"}},[_c('div',{staticClass:"checkbox",class:_vm.getTask('enableNotifications.isCanceled') ? 'opacity-25' : ''},[_c('input',{staticClass:"hidden",attrs:{"id":"notification","type":"checkbox","disabled":"true"},domProps:{"checked":_vm.getTask('enableNotifications.isCompleted')}}),_c('label',{attrs:{"for":"notification"},on:{"click":function($event){_vm.enableNotifications();
                _vm.$eventStore.general.sidebar('Setup progress: Enable notifications');}}},[_vm._v(" Enable notifications ")])]),(!_vm.isTaskCompletedOrCanceled('enableNotifications'))?_c('button',{staticClass:"button small-button text-secondary",staticStyle:{"padding":"0"},on:{"click":function($event){_vm.setTaskAsCanceled('enableNotifications');
              _vm.$eventStore.general.sidebar('Setup progress: Skip enabling notifications');}}},[_vm._v(" Skip ")]):_vm._e()]),_c('li',{staticClass:"flex items-center justify-between",attrs:{"data-cy":"setup-use-evergreen"}},[_c('div',{staticClass:"checkbox",class:_vm.getTask('setBestTweetsAsEvergreen.isCanceled') ? 'opacity-25' : ''},[_c('input',{staticClass:"hidden",attrs:{"id":"evergreen","type":"checkbox","disabled":"true"},domProps:{"checked":_vm.getTask('setBestTweetsAsEvergreen.isCompleted')}}),_c('label',{attrs:{"for":"evergreen"},on:{"click":function($event){_vm.isSetBestTweetsAsEvergreenModalVisible = true;
                _vm.showSetupProgress = false;
                _vm.$eventStore.general.sidebar('Setup progress: Set evergreen tweets');}}},[_vm._v(" Set best tweets as evergreen ")])]),(!_vm.isTaskCompletedOrCanceled('setBestTweetsAsEvergreen'))?_c('button',{staticClass:"button small-button text-secondary",staticStyle:{"padding":"0"},on:{"click":function($event){_vm.setTaskAsCanceled('setBestTweetsAsEvergreen')
              _vm.$eventStore.general.sidebar('Setup progress: Skip setting evergreen tweets');}}},[_vm._v(" Skip ")]):_vm._e()])]),_c('div',{staticClass:"grid justify-items-end"},[_c('button',{staticClass:"button primary-button small-button",attrs:{"data-cy":"setup-done-button"},on:{"click":function($event){_vm.showSetupProgress = false;
            _vm.$eventStore.general.sidebar('Setup progress: Done');}}},[_vm._v(" Done ")])])]),_c('div',{staticClass:"fixed z-20 inset-0 cursor-pointer bg-overlay",on:{"click":function($event){_vm.showSetupProgress = false}}})]):_vm._e(),_c('set-best-tweets-as-evergreen-modal',{attrs:{"show":_vm.isSetBestTweetsAsEvergreenModalVisible},on:{"close":function($event){_vm.isSetBestTweetsAsEvergreenModalVisible = false}}}),_c('portal-target',{attrs:{"name":"modal","multiple":""}}),(!_vm.isMobile && _vm.userProfile && _vm.isUserPlanBasicOrAbove(_vm.userProfile.customerStatus))?_c('new-button',{staticClass:"chat-button bottom-14 bg-ds-button-secondary-background",attrs:{"type":"custom"},on:{"click":_vm.toggleCrispChat}},[(!_vm.showCrispChat)?_c('inline-svg',{staticClass:"stroke-ds-button-primary-background",attrs:{"src":"/img/icons/new/help.svg"}}):_c('inline-svg',{attrs:{"src":"/img/icons/close.svg"}})],1):_vm._e(),_c('new-button',{staticClass:"!fixed bottom-7 right-7 z-10 gap-x-2.5",attrs:{"data-cy":"create-button"},on:{"click":_vm.handleFloatingCreateButton}},[_c('inline-svg',{staticClass:"h-6 w-6 stroke-current",attrs:{"src":"/img/icons/new-edit-icon.svg"}}),_vm._v(" Create ")],1),(_vm.showComposer)?_c('new-composer',{attrs:{"initialReelOptions":_vm.reelOptions,"defaultTab":_vm.newComposerDefaultTab,"timeFromParent":_vm.timeFromParent,"threadFromParent":_vm.threadFromParent,"categoriesFromParent":_vm.categoriesFromParent,"sourceFromParent":_vm.sourceFromParent,"postTypeFromParent":_vm.postTypeFromParent,"isAReplyFromParent":_vm.isAReplyFromParent,"isAMA":_vm.isAMA,"tweetsFromParent":_vm.tweetsFromParent,"showDraftsOnLoad":_vm.showDraftsOnLoad,"showPromptsOnLoad":_vm.showPromptsOnLoad,"hasStartedEditingFromParent":_vm.hasStartedEditing,"youtubeShortRef":_vm.youtubeShortRef},on:{"close":_vm.closeNewComposer}}):_vm._e(),(_vm.areAllScheduleSlotsEmpty)?_c('empty-schedule-alert-modal',{on:{"close":function($event){_vm.areAllScheduleSlotsEmpty = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }