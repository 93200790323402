<script>
  import { v1 as uuidv1 } from 'uuid';
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters, mapState } from 'vuex';
  import CustomerStatusMixin from './CustomerStatusMixin.vue';
  import {
    isPostBeyondSchedulingLimit,
    isUnlimitedSchedulingAllowedForUserPlan,
  } from '@/../functions/src/util/schedulingDateLimit';

  export default {
    mixins: [CustomerStatusMixin],
    computed: {
      ...mapGetters({
        userProfile: 'getUserProfile',
      }),
      ...mapState(['schedule']),
      timezone() {
        return lodash.get(this, 'userProfile.timezone', moment.tz.guess());
      },
    },
    data() {
      return {
        tweetIndexToFocus: null,
        mediaToDelete: [],
      };
    },
    methods: {
      setMediaToDelete(...media) {
        this.mediaToDelete = [...this.mediaToDelete, ...media];
      },
      emptyTweet(count) {
        return {
          status: '',
          count: count,
          fileList: [],
          media: [],
          guid: uuidv1(),
          mediaFile: [],
          published: false,
          quoteTweetData: null,
        };
      },
      setTweetIndexToFocus(index) {
        // In some cases tweetIndexToFocus doesn't change value which doesn't trigger the watcher
        // We reset it before updating to force the trigger
        this.tweetIndexToFocus = null;
        this.$nextTick(() => {
          this.tweetIndexToFocus = index;
        });
      },
      getTimeStatus(time, threadToEdit) {
        const getDiffBetweenLocalTimezoneAndOtherTimezone = () => {
          const localOffset = moment().utcOffset();
          const otherOffset = moment.tz(this.timezone).utcOffset();
          return localOffset - otherOffset;
        };
        const momentTime = moment(time);

        if (!momentTime._isValid) {
          return 'invalid';
        }

        const that = this;
        const getCurrentSlotLength = () => {
          if (that.schedule) {
            const selectedDate = Object.entries(that.schedule.getThreadsByDate()).find((elem) => {
              return moment(elem[0]).isSame(momentTime, 'day');
            });
            if (selectedDate) {
              const selectedTimeSlot = selectedDate[1].filter((elem) => {
                return (
                  !['empty', 'recurrent', 'evergreen'].includes(elem.slotType) &&
                  (!threadToEdit || elem.id !== threadToEdit.id) &&
                  moment(elem.time).isSame(momentTime, 'hour') &&
                  moment(elem.time).isSame(momentTime, 'minute')
                );
              });
              return selectedTimeSlot.length;
            }
          }
        };

        const minutesDifference = getDiffBetweenLocalTimezoneAndOtherTimezone();
        const clonedTime = lodash.clone(momentTime);
        clonedTime.add(minutesDifference, 'minute');

        const now = moment().add(minutesDifference, 'minutes').startOf('minute');
        const diff = clonedTime.diff(now, 'minutes');
        if (
          diff + minutesDifference <= 0 ||
          (diff + minutesDifference === 0 && clonedTime.minutes() === now.minutes())
        ) {
          return 'past';
        }

        const schedulingLimit = this.PLANS[this.userProfile.customerStatus].limits.scheduling;
        if (
          !isUnlimitedSchedulingAllowedForUserPlan(this.userProfile) &&
          isPostBeyondSchedulingLimit(clonedTime, this.userProfile.timezone, schedulingLimit)
        ) {
          return 'above limit';
        }

        if (clonedTime > moment().add(1, 'year')) {
          return 'more than one year';
        }

        const slotLength = getCurrentSlotLength();
        if (slotLength === 2) return 'slot full';
        return 'valid';
      },
      isTimeValid(time, threadToEdit) {
        switch (this.getTimeStatus(time, threadToEdit)) {
          case 'valid':
            return true;
          default:
            return false;
        }
      },
      getTimeError(time, threadToEdit) {
        const schedulingLimit = this.PLANS[this.userProfile.customerStatus].limits.scheduling;

        switch (this.getTimeStatus(time, threadToEdit)) {
          case 'invalid':
            return 'Invalid date.';
          case 'past':
            return 'The selected date is in the past.';
          case 'more than one year':
            return 'Scheduling is limited to 1 year from now.';
          case 'slot full':
            return 'You have already scheduled 2 tweets for the selected date.';
          case 'above limit':
            return `As a ${this.userPlan} user you are only allowed to schedule up to ${schedulingLimit.value} ${schedulingLimit.unit}.`;
          default:
            return '';
        }
      },
      isTweetPollInfoValid(tweet) {
        if (tweet.poll) {
          return (
            !lodash.isNil(tweet.poll.options[0]) &&
            tweet.poll.options[0].trim().length > 0 &&
            !lodash.isNil(tweet.poll.options[1]) &&
            tweet.poll.options[1].trim().length > 0
          );
        } else {
          return true;
        }
      },
      confirmPostNow(
        isThreadValid,
        isShareOnLinkedInEnabled,
        isShareOnFacebookEnabled,
        postNow,
        postType
      ) {
        if (!isThreadValid) return;

        const isLinkedInNotAllowed = this.isUserPlanBasicOrBelow(
          this.userProfile.customerStatus
        );
        let text = '';
        if (
          (postType === 'linkedin' || isShareOnLinkedInEnabled) &&
          isLinkedInNotAllowed &&
          this.isUserConnectedToLinkedIn
        ) {
          if (postType === 'linkedin') {
            text = 'Your LinkedIn post will not be published'
          } else {
            text = 'Your post will not be cross-posted to LinkedIn'
          }
          text +=
            ` because you don't have a ${this.getPlanLabel('standard')} subscription.<br>` +
            'You can <a target="_blank" href="/billing"><u>upgrade your subscription</u></a> then come back here to post.<br><br>' +
            'Do you still want to publish this tweet now?';
        } else if (isShareOnLinkedInEnabled && !this.isUserConnectedToLinkedIn) {
          if (isShareOnFacebookEnabled && !this.isUserConnectedToFacebookWithPage) {
            text =
              "Your LinkedIn and Facebook posts will not be published because your didn't connect your" +
              ' LinkedIn and your Facebook page or group to Hypefury.<br>' +
              'You can go to <a target="_blank" href="/settings"><u>the Settings page</u></a>' +
              ' to do that.<br><br>Do you still want to publish this tweet now?';
          } else {
            text =
              "Your LinkedIn post will not be published because your didn't connect your" +
              ' LinkedIn to Hypefury.<br>' +
              'You can go to <a target="_blank" href="/settings"><u>the Settings page</u></a>' +
              ' to do that.<br><br>Do you still want to publish this tweet now?';
          }
        } else if (
          isShareOnFacebookEnabled &&
          !this.isUserConnectedToFacebookWithPage &&
          !this.isAnyFacebookGroupSelected
        ) {
          text =
            "Your Facebook post will not be published because your didn't connect your" +
            ' Facebook page or group to Hypefury.<br>' +
            'You can go to <a target="_blank" href="/settings"><u>the Settings page</u></a>' +
            ' to do that.<br><br>Do you still want to publish this tweet now?';
        } else {
          let postTypeText = 'Twitter';

          if (postType === 'instagram' || this.selectedTab === 'reel-editor') {
            postTypeText = 'Instagram';
          } else if (postType === 'facebook') {
            postTypeText = 'Facebook';
          } else if (postType === 'linkedin') {
            postTypeText = 'LinkedIn';
          }

          text = `Are you sure you want to post to ${postTypeText} now?`;
        }
        this.swalModal({
          title: `Post Now`,
          html: text,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, post now',
          preConfirm: () => {
            postNow();
          },
        });
      },
    },
  };
</script>
