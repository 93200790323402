<template>
  <div
    class="form-group"
    :class="[
      {'lg:flex lg:items-center lg:justify-between' : label || $slots.label},
      `form-group-${align}`,
    ]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
      </label>
    </slot>
    <div 
      class=""
      :class="[
      {'input-group': hasIcon},
      {'focused': focused},
      {'input-group-alternative': alternative},
      {'mt-2 lg:mt-0 lg:w-1/2': label || $slots.label},
      inputGroupClasses
      ]">
      <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="prepend">
            <inline-svg :src="prependIcon" />
          </slot>
        </span>
      </div>
      <slot v-bind="slotData">
        <input
          :disabled="disabled"
          :value="value"
          :type="type"
          :min="min"
          :max="max"
          v-on="listeners"
          v-bind="$attrs"
          :valid="!error"
          :required="required"
          class="form-control"
          :class="[{'is-valid': valid === true}, {'is-invalid': error}, {'border-none': hasIcon}, inputClasses]">
      </slot>
      <div v-if="appendIcon || $slots.append"
           @click="appendIconClick"
           class="pr-2 cursor-pointer text-dark-mode-15 hover:text-primary">
              <slot name="append">
                <inline-svg :src="appendIcon" />
              </slot>
      </div>
      <slot name="infoBlock"></slot>
      <slot name="error">
        <div v-if="error" class="invalid-feedback" style="display: block;" v-html="error"></div>
      </slot>
      <slot name="success">
        <div class="valid-feedback" v-if="!error && valid">
          {{successMessage}}
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)"
      },
      group: {
        type: Boolean,
        description: "Whether input is an input group (manual override in special cases)"
      },
      valid: {
        type: Boolean,
        description: "Whether is valid",
        default: undefined
      },
      alternative: {
        type: Boolean,
        description: "Whether input is of alternative layout"
      },
      label: {
        type: String,
        description: "Input label (text before input)"
      },
      error: {
        type: String,
        description: "Input error (below input)"
      },
      successMessage: {
        type: String,
        description: "Input success message",
        default: 'Looks good!'
      },
      labelClasses: {
        type: String,
        description: "Input label css classes",
        default: "form-control-label text-dark-mode-15"
      },
      inputClasses: {
        type: String,
        description: "Input css classes"
      },
      inputGroupClasses: {
        type: String,
        description: "Input group css classes"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      min: {
        type: String,
        description: "Min value (Hypefury uses it for datetime-local)"
      },
      max: {
        type: String,
        description: "Max value (Hypefury uses it for datetime-local)"
      },
      type: {
        type: String,
        description: "Input type",
        default: "text"
      },
      appendIcon: {
        type: String,
        description: "Append icon (right)"
      },
      prependIcon: {
        type: String,
        description: "Prepend icon (left)"
      },
      align: {
        type: String,
        description: "Input aligntment (row or column)",
        default: "row"
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        focused: false
      };
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur
        };
      },
      slotData() {
        return {
          focused: this.focused,
          error: this.error,
          ...this.listeners
        };
      },
      hasIcon() {
        const { append, prepend } = this.$slots;
        return (
          append !== undefined ||
          prepend !== undefined ||
          this.appendIcon !== undefined ||
          this.prependIcon !== undefined ||
          this.group
        );
      }
    },
    methods: {
      appendIconClick() {
        this.$emit('append-icon-click');
      },
      updateValue(evt) {
        let value = evt.target.value;
        this.$emit("input", value);
      },
      onFocus(evt) {
        this.focused = true;
        this.$emit("focus", evt);
      },
      onBlur(evt) {
        this.focused = false;
        this.$emit("blur", evt);
      }
    }
  };
</script>
<style>
</style>
