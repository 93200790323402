import lodash from 'lodash';

export function displayIconMessage(type, thread) {
  switch (type) {
    case 'Growth Program':
      return `Growth Program`;
    case 'LinkedIn':
      return `This will also be posted to LinkedIn`;
    case 'LinkedIn Carousel':
      return `Your tweet${
        thread.tweets.length > 1 ? 's' : ''
      } will be turned into a LinkedIn carousel`;
    case 'Instagram': {
      const hasMultipleTweets = thread.tweets.length > 1;
      return `${
        hasMultipleTweets ? 'Tweets in this thread' : 'This tweet'
      } will be posted on Instagram as ${hasMultipleTweets ? 'a carousel of images' : 'an image'}`;
    }
    case 'Facebook':
      return `This will also be posted to Facebook`;
    case 'Thread':
      return `This will be a thread`;
    case 'Delay Message':
      return `Tweets will be posted with a delay of ${thread.delayBetweenTweets} minutes between them`;
    case 'Autoplug':
      return Number.isInteger(thread.autoplug.minFavorites)
        ? `Follow-up tweet will be posted if this post reaches ${thread.autoplug.minFavorites} likes`
        : `Follow-up tweet will be posted if this post reaches ${thread.autoplug.minRetweets} retweets`;
    case 'Conditional Retweet':
      return Number.isInteger(thread.conditionalRetweetsConditions.minFavoritesThreshold)
        ? `Retweet will be posted if this post reaches ${
            thread.conditionalRetweetsConditions.minFavoritesThreshold
          } likes ${
            thread.type === 'retweet'
              ? ''
              : `after ${thread.conditionalRetweetsConditions.delayForRetweet} ${
                  isNaN(thread.conditionalRetweetsConditions.delayForRetweet) ? '' : 'hours'
                }`
          }`
        : `Retweet will be posted if this post reaches ${
            thread.conditionalRetweetsConditions.minRetweetsThreshold
          } retweets ${
            thread.type === 'retweet'
              ? ''
              : `after ${thread.conditionalRetweetsConditions.delayForRetweet} ${
                  isNaN(thread.conditionalRetweetsConditions.delayForRetweet) ? '' : 'hours'
                }`
          }`;
    case 'Evergreen Post':
      return !thread.isFavorite ? 'Set as an Evergreen Post' : 'Unset as an Evergreen Post';
    case 'Pin Post':
      return !thread.isPinned ? 'Pin Post' : 'Unpin Post';
    case 'autoDM': {
      const requiresRetweet = thread.autoDM.triggerOptions.includes('retweet');
      const requiresReply = thread.autoDM.triggerOptions.includes('reply');
      const requiresOnlyOneOption = thread.autoDM.requiresOnlyOneOption;
      const messages = lodash.get(thread, 'autoDM.messages', []);
      const repliesWithText = messages
        .filter((message) => message.reply)
        .map((message) => message.reply);
      const hasRepliesWithoutText = Boolean(messages.some((message) => message.reply === ''));
      const requiresAnyReply = requiresReply && hasRepliesWithoutText;

      let message = 'This will send a DM automatically when a user ';

      if (requiresReply) {
        message += `${requiresAnyReply ? `replies with any text` : `replies`} ${
          repliesWithText.length > 0
            ? `${hasRepliesWithoutText ? 'or replies with' : ''} ${repliesWithText
                .map((reply) => `"${reply}"`)
                .join(' or ')} `
            : ''
        }`;
      }

      if (requiresRetweet) {
        message += `${requiresReply ? (requiresOnlyOneOption ? 'or' : 'and') : ''} retweets`;
      }

      return `${message.trim()}.`;
    }
    default:
      return null;
  }
}
