<template>
  <new-drop-down
    placement="right"
    buttonType="custom"
    buttonClasses="flex items-center text-main-color-100 text-sm"
    data-cy="compose-delay-tweets"
  >
    <template v-slot:button="slotProps">
      {{ getDelayBetweenTweets }} delay <span class="ml-1 hidden sm:inline">between posts</span>
      <inline-svg
        src="/img/icons/arrow-down-icon.svg"
        class="ml-2 h-2.5 w-2.5 stroke-current"
        :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
      />
    </template>
    <template>
      <div class="flex flex-col items-start justify-center gap-y-2">
        <template v-for="(item, index) in dropdownValues">
          <new-button
            :key="item.label"
            v-if="index !== dropdownValues.length - 1"
            @click="setDelayBetweenTweets(item.value)"
            data-cy="compose-delay-option"
            type="custom"
            class="dark-mode-text-white text-main-color-100"
          >
            <span
              class="outline-blue mr-3.5 inline-block h-2.5 w-2.5 rounded-full"
              :class="{
                'bg-main-color-100': delayBetweenTweets === item.value && !isCustomValue,
              }"
            ></span>
            {{ item.label }}
          </new-button>
          <div
            class="flex w-full flex-col gap-y-2"
            :key="item.label"
            v-if="index === dropdownValues.length - 1"
          >
            <hr class="dropdown-separator" />
            <div class="flex w-full items-center">
              <new-button
                @click="setCustomDelayBetweenTweets"
                data-cy="compose-delay-option"
                type="custom"
              >
                <span
                  class="outline-blue mr-1 inline-block h-2.5 w-2.5 rounded-full"
                  :class="{ 'bg-main-color-100': isCustomValue }"
                ></span>
              </new-button>
              <input
                @input="setCustomDelayBetweenTweets"
                v-model="item.value"
                type="number"
                min="0"
                class="dark-mode-text-white w-full border-0 text-main-color-100 sm:w-32"
                placeholder="Custom delay..."
              />
              <div class="flex rounded-md border-2 border-main-color-30 p-0.5">
                <new-button @click="setCustomDelayUnit('m')" type="custom">
                  <span
                    class="rounded w-6 py-0.5 leading-4"
                    :class="{
                      'bg-main-color-100 text-white': customDelayUnit === 'm',
                      'bg-transparent text-main-color-30': customDelayUnit !== 'm',
                    }"
                    >M</span
                  >
                </new-button>
                <new-button @click="setCustomDelayUnit('hr')" type="custom">
                  <span
                    class="rounded ml-1 w-6 py-0.5 leading-4"
                    :class="{
                      'bg-main-color-100 text-white': customDelayUnit === 'hr',
                      'bg-transparent text-main-color-30': customDelayUnit !== 'hr',
                    }"
                    >Hr</span
                  >
                </new-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </new-drop-down>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  export default {
    props: {
      threadToEdit: {
        type: Object,
        default: null,
      },
      delayBetweenTweetsFromParent: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      getDelayBetweenTweets() {
        if (isNaN(this.delayBetweenTweets)) return '0m';
        if (this.delayBetweenTweets < 60) return `${this.delayBetweenTweets}m`;

        return `${parseFloat(Number(this.delayBetweenTweets / 60).toFixed(1))}hr`;
      },
    },
    data() {
      return {
        delayBetweenTweets: 0,
        dropdownValues: [
          { label: 'None', value: 0 },
          { label: '2m delay', value: 2 },
          { label: '5m delay', value: 5 },
          { label: '15m delay', value: 15 },
          { label: '1hr delay', value: 60 },
          { label: 'custom', value: null },
        ],
        customDelayUnit: 'm',
        isCustomValue: false,
      };
    },
    mounted() {
      if (!this.delayBetweenTweetsFromParent) {
        this.updateValuesFromUserProfile();
      } else {
        this.delayBetweenTweets = this.delayBetweenTweetsFromParent;
      }
    },
    updated() {
      this.updateParentValues();
    },
    methods: {
      setDelayBetweenTweets(value) {
        this.isCustomValue = false;
        this.delayBetweenTweets = value;
      },
      updateParentValues() {
        this.$emit(
          'values-set',
          !isNaN(this.delayBetweenTweets) ? parseInt(this.delayBetweenTweets) : 0
        );
      },
      updateValueFromThread() {
        this.delayBetweenTweets = this.threadToEdit.delayBetweenTweets || 0;
      },
      updateValuesFromUserProfile() {
        if (!this.userProfile.delayBetweenTweetsConditions) return;

        const delayFromUserProfile = lodash.get(
          this.userProfile,
          'delayBetweenTweetsConditions.delayBetweenTweets',
          0
        );

        this.delayBetweenTweets = delayFromUserProfile;
        this.updateParentValues();
      },
      setCustomDelayBetweenTweets() {
        this.isCustomValue = true;
        if (typeof this.dropdownValues[this.dropdownValues.length - 1].value !== 'number')
          this.delayBetweenTweets = 0;

        if (this.customDelayUnit === 'm') {
          this.delayBetweenTweets = parseFloat(
            this.dropdownValues[this.dropdownValues.length - 1].value
          );
        } else {
          this.delayBetweenTweets =
            parseFloat(this.dropdownValues[this.dropdownValues.length - 1].value) * 60;
        }
      },
      setCustomDelayUnit(unit) {
        this.customDelayUnit = unit;
        if (!this.isCustomValue) return;
        this.setCustomDelayBetweenTweets();
      },
    },
    watch: {
      threadToEdit(thread) {
        if (thread) {
          this.updateValueFromThread();
        }
      },
    },
  };
</script>

<style scoped>
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  .mode--light input[type='number']::placeholder {
    @apply text-main-color-100;
  }
  .mode--dark input[type='number']::placeholder {
    @apply text-white;
  }
</style>
