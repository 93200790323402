<template>
  <popper
    :delay-on-mouse-over="delayOnMouseOver"
    :delay-on-mouse-out="delayOnMouseOut"
    :disabled="disabled"
    :options="{ placement: placement }"
    :placement="placement"
    :trigger="trigger"
    :visible-arrow="visibleArrow"
  >
    <div class="popper popper-new-ui">
      <div data-cy="tooltip-text" class="normal-nums" v-html="content"></div>
      <div v-if="footer" class="mt-1" v-html="footer"></div>
    </div>

    <slot slot="reference"></slot>
  </popper>
</template>

<script>
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';

  export default {
    name: 'tooltip',
    props: {
      content: {
        type: String,
        default: 'Tooltip Content',
      },
      delayOnMouseOver: {
        type: Number,
        default: 10,
        description: 'Delay in ms before showing popper during a mouse over',
      },
      delayOnMouseOut: {
        type: Number,
        default: 10,
        decription: 'Delay in ms before hiding popper during a mouse out',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      footer: {
        type: String,
        default: '',
      },
      placement: {
        type: String,
        default: 'top',
      },
      trigger: {
        type: String,
        default: 'hover',
      },
      visibleArrow: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      popper: Popper,
    },
  };
</script>
<style lang="scss">
  .new-ui {
    .popper-new-ui {
      @apply rounded-lg bg-ds-foreground text-sm text-ds-text-primary shadow-tooltip;
    }
    .popper {
      min-width: fit-content !important;
      @include md {
        min-width: max-content !important;
      }
      &[x-placement^='top'] .popper__arrow {
        bottom: -6px !important;
        border-color: var(--foreground) !important;
      }

      &[x-placement^='bottom'] .popper__arrow {
        bottom: -4px !important;
        border-color: var(--foreground) !important;
      }
    }
  }
</style>
