import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import growthProgramDAO from '@/dao/growthProgram';
import lodash from 'lodash';
import moment from 'moment';
import { Thread } from '@/models/Thread';
const fb = require('@/firebase');

const dao = {
  growthProgram: growthProgramDAO,
  integrations: {},
  userProfile: {},
  csvUpload: {},
  posts: {},
  gumroadSales: {},
  categories: {},
  recurrentPosts: {},
  dmCampaign: {},
  amaReplies: {},
  appSettings: {},
  youtubeVideos: {},
  youtubeShorts: {},
};

dao.userProfile.saveOpenAIKey = (userId, openAIKey) => {
  return fb.usersCollection.doc(userId).update({
    'settings.openAIKey': openAIKey,
  });
};

dao.userProfile.updateUserProfileIfNecessary = (
  userProfile,
  twitterUserInfo
  // theme
) => {
  const isUserSecondaryAccount = userProfile.twitterId !== twitterUserInfo.id;
  if (isUserSecondaryAccount) {
    return null;
  }

  const updateMap = {};

  if (
    userProfile.name !== twitterUserInfo.name ||
    userProfile.photoURL !== twitterUserInfo.profile_image_url ||
    userProfile.username !== twitterUserInfo.username ||
    userProfile.isTwitterVerified !== twitterUserInfo.verified
  ) {
    updateMap.name = twitterUserInfo.name;
    updateMap.photoURL = twitterUserInfo.profile_image_url;
    updateMap.username = twitterUserInfo.username;
    updateMap.isTwitterVerified = twitterUserInfo.verified;
  }

  if (lodash.isEmpty(updateMap)) {
    return null;
  }

  return fb.usersCollection.doc(userProfile.uid).update({ ... updateMap });
};

dao.userProfile.getAllActiveUsers = () => {
  return fb.usersCollection.where('subscriptionStatus', '==', 'active').get();
};

dao.userProfile.updatePreferGlobalAutoplugSettings = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.preferGlobalAutoplugSettings': value});
}

dao.userProfile.updateShowOnlyFilledHours = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.showOnlyFilledHours': value});
}

dao.userProfile.getUser = (userId) => {
  return fb.usersCollection.doc(userId).get();
}

dao.userProfile.updateSchedule = (userId, schedule) => {
  return fb.usersCollection
    .doc(userId)
    .update({schedule: schedule.getScheduleForDB()});
};

dao.userProfile.updateShouldAutoFillEmptyComposer = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.shouldAutoFillEmptyComposer': value});
};

dao.userProfile.updateCreateTweetOnEnterPress = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.createTweetOnEnterPressEnabled': value });
};

dao.userProfile.updateShouldSplitLongText = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.shouldSplitLongText': value});
};

dao.userProfile.saveGlobalConditionalRetweetsConditionsToFirestore =
  (userId, conditionalRetweetsConditions) => {
    return fb.usersCollection
      .doc(userId)
      .update({
        'settings.conditionalRetweetsConditions': conditionalRetweetsConditions
      });
  };

dao.userProfile.saveCategoryAutoplugConditions = (userId, autoplugConditions, categoryId) => {
  const autoplugSettingsToUpdate = `settings.categoriesAutoplugs.categories.${categoryId}`

  return fb.usersCollection
    .doc(userId)
    .update({
      [autoplugSettingsToUpdate]: autoplugConditions,
    });
};

dao.userProfile.saveAutoplugConditionsToFirestore = (userId, autoplugConditions) =>
  fb.usersCollection.doc(userId).update({
    'settings.autoplugConditions': autoplugConditions,
  });

dao.userProfile.updateInspirationTweetsType = (userId, value) => {
  if (!['always', 'never', 'writersblock'].includes(value)) {
    throw new Error(`Value ${value} is not a valid inspration tweet type value.`);
  }
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.inspirationTweetsType': value });
};

dao.userProfile.updateShowInspirationTweetsInComposer = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.showInspirationTweetsInComposer': value });
};

dao.userProfile.increaseInspirationTweetCopiedCount = (userId) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'inspirationTweetCopiedCount': firebase.firestore.FieldValue.increment(1) });
};

dao.userProfile.increaseInspirationTweetRefreshedCount = (userId) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'inspirationTweetRefreshedCount': firebase.firestore.FieldValue.increment(1) });
};

dao.userProfile.updateDefaultHomepageSettings  = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.defaultHomepage': value });

};

dao.userProfile.resetTweetingStreak = (userId) => {
  return fb.usersCollection.doc(userId).update({ tweetingStreak: 0 });
};

dao.userProfile.updateScheduledPostsCount = (userId, postsCount) =>
  fb.usersCollection.doc(userId).update({
    currentlyScheduledPostsCount: postsCount,
  });

dao.userProfile.updateDraftPostCount = (userId, postsCount) =>
  fb.usersCollection.doc(userId).update({
    draftPostCount: postsCount,
  });

dao.integrations.cancelGumroadSale = gumroadSaleId => fb.gumroadSalesCollection.doc(gumroadSaleId)
  .update({ wasCanceled: true });

dao.integrations.deleteGumroadInformation = userId => fb.usersCollection.doc(userId)
  .update({ 'integrations.gumroad': null });

dao.integrations.deleteGumroadSale = gumroadSaleId => fb.gumroadSalesCollection.doc(gumroadSaleId)
  .delete();

dao.integrations.updateGumroadAccessToken = (userId, accessToken, refreshToken, createdAt) =>
  fb.usersCollection.doc(userId)
    .update({
      'integrations.gumroad.tokenInfo.accessToken': accessToken,
      'integrations.gumroad.tokenInfo.refreshToken': refreshToken,
      'integrations.gumroad.tokenInfo.createdAt': createdAt,
    });

dao.integrations.cancelTimeBasedSale = timeBasedSaleId => fb.threadsCollection.doc(timeBasedSaleId)
  .update({ isCancelled: true, updated_at: firebase.firestore.FieldValue.serverTimestamp() });

dao.integrations.deleteTimeBasedSale = timeBasedSaleId => fb.threadsCollection.doc(timeBasedSaleId)
  .delete();

dao.userProfile.createAutoplugTemplate = (template, userProfile) => {
  return fb.usersCollection
    .doc(userProfile.uid)
    .update({ autoplugTemplates: firebase.firestore.FieldValue.arrayUnion(template) });
};

dao.userProfile.updateAutoplugTemplates = (autoplugTemplates, userProfile) => {
  return fb.usersCollection.doc(userProfile.uid).update({ autoplugTemplates });
}

dao.integrations.deleteLinkedInInformation = userId => fb.usersCollection.doc(userId)
  .update({ 'integrations.linkedin': null });

dao.integrations.deleteFacebookInformation = userId => fb.usersCollection.doc(userId)
  .update({ 'integrations.facebook': null });

dao.userProfile.setFacebookPage = (userId, pageId) => fb.usersCollection.doc(userId)
  .update({ 'integrations.facebook.page.id': pageId });

dao.userProfile.updateFacebookGroups = (userId, groups) =>
  fb.usersCollection.doc(userId).update({
    'integrations.facebook.groups': groups,
  });

dao.userProfile.hideReply = (userId, replyId) => fb.usersCollection.doc(userId)
  .update({ 'integrations.twitter.hiddenRepliesIds': firebase.firestore.FieldValue.arrayUnion(replyId) });

dao.userProfile.updateIsShareOnLinkedInEnabled = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.isShareOnLinkedInEnabled': value});
};

dao.userProfile.hideNewUIAlert = (userId) => {
  return fb.usersCollection
    .doc(userId)
    .update({'newUIAlert.isHiddenByUser': true});
};

dao.integrations.updateLinkedInAccessToken = (
  userId,
  accessToken,
  createdAt,
  expiresIn,
  refreshToken,
  refreshTokenExpiresIn
) =>
  fb.usersCollection.doc(userId).update({
    'integrations.linkedin.tokenInfo.accessToken': accessToken,
    'integrations.linkedin.tokenInfo.expiresIn': expiresIn,
    'integrations.linkedin.tokenInfo.createdAt': createdAt,
    'integrations.linkedin.tokenInfo.refreshToken': refreshToken,
    'integrations.linkedin.tokenInfo.refreshTokenExpiresIn': refreshTokenExpiresIn,
    'integrations.linkedin.tokenInfo.hasRefreshToken': true,
  });

dao.integrations.updateFacebookAccessToken = (userId, accessToken, createdAt, expiresIn) =>
  fb.usersCollection.doc(userId)
    .update({
      'integrations.facebook.tokenInfo.accessToken': accessToken,
      'integrations.facebook.tokenInfo.createdAt': createdAt,
      'integrations.facebook.tokenInfo.expiresIn': expiresIn,
      'integrations.facebook.tokenInfo.isTokenExtended': false,
      'integrations.facebook.page.connected': null,
    });

dao.integrations.updateInstagramAuthData = (
  userId,
  name,
  instagramId,
  accessToken,
  createdAt,
  pagesData,
) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.instagram.tokenInfo.name': name,
    'integrations.instagram.tokenInfo.accessToken': accessToken,
    'integrations.instagram.tokenInfo.id': instagramId,
    'integrations.instagram.tokenInfo.createdAt': createdAt,
    'integrations.instagram.pages': pagesData,
  });
};

dao.integrations.updateInstagramAccount = (userId, page) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.instagram.tokenInfo.id': page.id,
    'integrations.instagram.tokenInfo.name': page.name,
    'integrations.instagram.tokenInfo.accessToken': page.accessToken,
  });
};

dao.integrations.deleteInstagramInformation = (userId) =>
  fb.usersCollection.doc(userId).update({
    'integrations.instagram.tokenInfo': null,
    'integrations.instagram.pages': firebase.firestore.FieldValue.delete(),
  });

dao.integrations.deleteThreadsInformation = (userId) =>
  fb.usersCollection.doc(userId).update({
    'integrations.threads.tokenInfo': null,
  });

dao.integrations.saveCaption = (userId, caption) => {
  return fb.usersCollection.doc(userId)
    .update({ 'integrations.instagram.caption': caption });
};

dao.integrations.updateTweetshotSettings = (userId, tweetshotLayout, tweetshotTheme) => {
  return fb.usersCollection.doc(userId)
    .update({
      'settings.tweetshot.layout': tweetshotLayout,
      'settings.tweetshot.theme': tweetshotTheme,
    });
};

dao.integrations.updateThreadsAccessToken = (userId, threadsData) =>
  fb.usersCollection.doc(userId).update({
    'integrations.threads.tokenInfo': threadsData,
  });

dao.csvUpload.createCSVUpload = (
  fileId,
  processed,
  size,
  userRef,
  time,
  fileName,
  setPostsAsFavorite,
  categories,
  crossPostingTargets,
  setPostsAsRecurrentPosts
) => {
  const data = {
    fileId,
    processed,
    size,
    userRef,
    time,
    fileName,
    setPostsAsFavorite,
    categories,
    crossPostingTargets,
    setPostsAsRecurrentPosts,
  };

  return fb.csvUploadsCollection.doc(fileId).set(data);
};

dao.csvUpload.getLatestCSVUploadWithSameName = (fileName, userId) => {
  return fb.csvUploadsCollection
    .where('fileName', '==', `csv-uploads/${userId}-${fileName}`)
    .orderBy('time', 'desc')
    .limit(1)
    .get()
    .then((csvFilesWithSameName) =>
      csvFilesWithSameName.empty ? null : csvFilesWithSameName.docs[0].data()
    );
};

dao.growthProgram.getUserGrowthProgramThreads = (userDoc, growthProgramRef) => {
  return fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', userDoc.ref)
    .where('time', '==', null)
    .where('growthProgram', '==', growthProgramRef)
    .where('source', '==', 'growth-program')
    .get();
};

dao.posts.canAddMorePostsInSlot = async (userId, time) => {
  const userRef = fb.usersCollection.doc(userId);
  const posts = await fb.threadsCollection
    .where('user', '==', userRef)
    .where('time', '==', time)
    .where('deleted', '==', false)
    .where('postNow', '==', false)
    .where('scheduled', '==', false)
    .limit(2)
    .get();

  return posts.docs.length < 2;
}

dao.posts.switchPostsTime = (post1, post2, time1, time2) => {
  const batch = fb.firebase.firestore().batch();
  batch.update(fb.threadsCollection.doc(post1.id), {
    time: time2,
    updated_at: firebase.firestore.FieldValue.serverTimestamp()
  });
  batch.update(fb.threadsCollection.doc(post2.id), {
    time: time1,
    updated_at: firebase.firestore.FieldValue.serverTimestamp()
  });
  return batch.commit();
}

dao.posts.getPostsQueriesForHistory = (userId, timeRange) => {
  const baseQuery = fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', fb.usersCollection.doc(userId))
    .where('scheduled', '==', true);

  const all = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .orderBy('time', 'desc')
        .limit(10)
    : baseQuery;

  const deleted = fb.threadsCollection
    .where('deleted', '==', true)
    .where('publishingError', '==', null)
    .where('user', '==', fb.usersCollection.doc(userId));

  const hypefuryTweets = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .where('source', '==', 'hypefury-app')
        .orderBy('time', 'desc')
        .limit(10)
    : baseQuery.where('source', '==', 'hypefury-app').limit(10);

  const retweets = baseQuery
    .where('publishingError', '==', null)
    .orderBy('retweetCountOfTheFirstTweet', 'desc')
    .limit(10);

  const likes = baseQuery
    .where('publishingError', '==', null)
    .orderBy('favoriteCountOfTheFirstTweet', 'desc')
    .limit(10);

  const impressions = baseQuery
    .where('publishingError', '==', null)
    .orderBy('impressionsCountOfTheFirstTweet', 'desc')
    .limit(10);

  const replies = baseQuery
    .where('publishingError', '==', null)
    .orderBy('replyCountOfTheFirstTweet', 'desc')
    .limit(10);

  const bookmarks = baseQuery
    .where('publishingError', '==', null)
    .orderBy('bookmarkCountOfTheFirstTweet', 'desc')
    .limit(10);

  const profileClicks = baseQuery
    .where('publishingError', '==', null)
    .orderBy('profileClicksCountOfTheFirstTweet', 'desc')
    .limit(10);

  return {
    all,
    deleted,
    retweets,
    likes,
    impressions,
    replies,
    bookmarks,
    profileClicks,
    hypefuryTweets,
  };
};

dao.posts.getPostsQueriesForHistoryCategory = (userId, categoryId, timeRange, postsOrder) => {
  const categoryRef = fb.categoriesCollection.doc(categoryId);
  const baseQuery =
    categoryId === 'non-categorized'
      ? fb.threadsCollection
          .where('deleted', '==', false)
          .where('categories', '==', [])
          .where('isRecurrentPost', '==', true)
          .where('user', '==', fb.usersCollection.doc(userId))
          .where('isCloned', '==', false)
      : fb.threadsCollection
          .where('deleted', '==', false)
          .where('categories', 'array-contains', categoryRef)
          .where('user', '==', fb.usersCollection.doc(userId))
          .where('isCloned', '==', false);

  const all = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .orderBy('time', 'desc')
        .limit(10)
    : fb.threadsCollection
        .where('__name__', 'in', postsOrder);

  const deleted = fb.threadsCollection
    .where('deleted', '==', true)
    .where('categories', 'array-contains', categoryRef)
    .where('publishingError', '==', null)
    .where('user', '==', fb.usersCollection.doc(userId))
    .where('isCloned', '==', false);

  const hypefuryTweets = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .where('source', '==', 'hypefury-app')
        .orderBy('time', 'desc')
        .limit(10)
    : baseQuery.where('source', '==', 'hypefury-app').limit(10);

    const disabled = timeRange
      ? baseQuery
          .where('time', '>', new Date(timeRange.from))
          .where('time', '<', new Date(timeRange.to))
          .where('isRecurrentPostDisabled', '==', true)
          .orderBy('time', 'desc')
          .limit(10)
      : baseQuery.where('isRecurrentPostDisabled', '==', true).limit(10);

  const retweets = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('retweetCountOfTheFirstTweet', 'desc')
    .limit(10);

  const likes = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('favoriteCountOfTheFirstTweet', 'desc')
    .limit(10);

  const impressions = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('impressionsCountOfTheFirstTweet', 'desc')
    .limit(10);

  const replies = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('replyCountOfTheFirstTweet', 'desc')
    .limit(10);

  const bookmarks = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('bookmarkCountOfTheFirstTweet', 'desc')
    .limit(10);

  const profileClicks = baseQuery
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('profileClicksCountOfTheFirstTweet', 'desc')
    .limit(10);

  return {
    all,
    deleted,
    retweets,
    likes,
    impressions,
    replies,
    bookmarks,
    profileClicks,
    hypefuryTweets,
    disabled,
  };
};

dao.posts.getPostsQueriesForEvergreenQueue = (userId, timeRange) => {
  const baseQuery = fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', fb.usersCollection.doc(userId))
    .where('isFavorite', '==', true);

  const all = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .orderBy('time', 'desc')
        .limit(10)
    : baseQuery;

  const hypefuryTweets = timeRange
    ? baseQuery
        .where('time', '>', new Date(timeRange.from))
        .where('time', '<', new Date(timeRange.to))
        .where('source', '==', 'hypefury-app')
        .orderBy('time', 'desc')
        .limit(10)
    : baseQuery.where('source', '==', 'hypefury-app').limit(10);

  const retweets = baseQuery.orderBy('retweetCountOfTheFirstTweet', 'desc').limit(10);

  const likes = baseQuery.orderBy('favoriteCountOfTheFirstTweet', 'desc').limit(10);

  const impressions = baseQuery.orderBy('impressionsCountOfTheFirstTweet', 'desc').limit(10);

  const replies = baseQuery.orderBy('replyCountOfTheFirstTweet', 'desc').limit(10);

  const bookmarks = baseQuery.orderBy('bookmarkCountOfTheFirstTweet', 'desc').limit(10);

  const profileClicks = baseQuery.orderBy('profileClicksCountOfTheFirstTweet', 'desc').limit(10);

  return { all, retweets, likes, impressions, replies, bookmarks, profileClicks, hypefuryTweets };
};

dao.posts.assignCategoriesToPost = (post, categories) => {
  return fb.threadsCollection.doc(post.id).update({
    categories
  });
}

dao.posts.cancelPostingOfThreadWithDelayBetweenTweets = (threadId) => {
  return fb.threadsCollection.doc(threadId).update({
    delayBetweenTweets: null,
    isCancelled: true,
    timeWithDelay: null,
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.posts.updateThreadDelayBetweenTweets = (threadId) => {
  return fb.threadsCollection.doc(threadId).update({
    delayBetweenTweets: null,
    timeWithDelay: null,
    postNow: true,
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.posts.saveThreadsToFirestore = (threads, userId) => {
  const user = fb.usersCollection.doc(userId);
  return Promise.all(threads.map((thread) => {
    return fb.threadsCollection.add({
      ...thread,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      user
    })
  }))
}

dao.posts.toggleFavorite = (threadId, isFavorite) => {
  const newValue = !isFavorite;
  return fb.threadsCollection
    .doc(threadId)
    .update({ isFavorite: newValue, updated_at: firebase.firestore.FieldValue.serverTimestamp() });
};

dao.posts.togglePinPost = (threadId, isPinned) => {
  const newValue = !isPinned;
  return fb.threadsCollection
    .doc(threadId)
    .update({ isPinned: newValue, updated_at: firebase.firestore.FieldValue.serverTimestamp() });
};

dao.posts.getBestTweets = async (user) => {
  const MAX_BEST_TWEETS = 50;

  const userRef = fb.usersCollection.doc(user.uid);
  const allPosts = await fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', userRef)
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .orderBy('favoriteCountOfTheFirstTweet', 'desc')
    .get();

  let tenPercentOfPosts = Math.round(allPosts.docs.length * 0.1);

  /*
   * If user has less than 5 posts, the 10% of those will be a number lower than 0.5 and
   * when applying Math.round it will return 0, so we need to at least take 1 post in that case.
   */

  if (allPosts.docs.length < 5 && allPosts.docs.length > 0) {
    tenPercentOfPosts = 1;
  }

  const bestTweets = lodash.take(
    allPosts.docs,
    tenPercentOfPosts < MAX_BEST_TWEETS ? tenPercentOfPosts : MAX_BEST_TWEETS
  );

  return bestTweets.map(post => Thread.buildFromFirestore(post, user.timezone));
};

dao.posts.getMostRecentThreads = async (user) => {
  const MAX_TWEETS = 200;

  const userRef = fb.usersCollection.doc(user.uid);
  const allPosts = await fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', userRef)
    .where('scheduled', '==', true)
    .where('publishingError', '==', null)
    .where('source', 'in', ['hypefury-app', 'twitter', 'csv'])
    .where('type', '==', 'post')
    .orderBy('time', 'desc')
    .limit(MAX_TWEETS)
    .get();

  return allPosts.docs.map(post => Thread.buildFromFirestore(post, user.timezone));
};

dao.categories.findByName = (name, user) => {
  return fb.categoriesCollection
    .where('name', '==', name)
    .where('userRef', '==', user)
    .get();
}

dao.categories.update = (categoryId, name, color) => {
 return fb.categoriesCollection.doc(categoryId).update({
   name: name,
   color: color,
   updated_at: firebase.firestore.FieldValue.serverTimestamp()
 });
}

dao.categories.create = (name, color, isEnabled, uid) => {
  const userRef = fb.usersCollection.doc(uid);
  return fb.categoriesCollection.add({
    name: name,
    userRef: userRef,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    numberOfPosts: 0,
    color: color,
    postsOrder: [],
    isEnabled,
  });
};

dao.categories.swapPostsOrder = (categoryId, firstPostId, secondPostId) => {
  return fb.firestore.runTransaction(async (transaction) => {
    const category = await transaction.get(fb.categoriesCollection.doc(categoryId));
    const { postsOrder } = category.data();
    const newPostsOrder = [...postsOrder];
    const postsIds = postsOrder.map((post) => post.id);
    const indexOfFirstPost = postsIds.indexOf(firstPostId);
    const indexOfSecondPost = postsIds.indexOf(secondPostId);

    newPostsOrder[indexOfFirstPost] = fb.threadsCollection.doc(secondPostId);
    newPostsOrder[indexOfSecondPost] = fb.threadsCollection.doc(firstPostId);

    return transaction.update(fb.categoriesCollection.doc(categoryId), {
      postsOrder: newPostsOrder,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  });
};

dao.categories.updatePostsOrder = (categoryId, postsOrder) => {
  return fb.categoriesCollection.doc(categoryId).update({
    postsOrder,
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.categories.delete = (categoryId) => {
  return fb.categoriesCollection.doc(categoryId).delete();
};

dao.categories.skipTime = (categoryId, time) => {
  return fb.categoriesCollection.doc(categoryId).update({
    timesToSkip: firebase.firestore.FieldValue.arrayUnion(time),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.categories.restoreTime = (categoryId, time) => {
  return fb.categoriesCollection.doc(categoryId).update({
    timesToSkip: firebase.firestore.FieldValue.arrayRemove(time),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.categories.toggleEnabledState = (categoryId, isEnabled) => {
  return fb.categoriesCollection.doc(categoryId).update({
    isEnabled,
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.gumroadSales.getSalesOfCurrentMonth = (userId) => {
  return fb.gumroadSalesCollection
    .where('startsAt', '>=', moment().startOf('month').toDate())
    .where('startsAt', '<=', moment().add(1, 'month').startOf('month').toDate())
    .where('userRef', '==', fb.usersCollection.doc(userId))
    .get();
};

dao.gumroadSales.saveDefaultWording = (userId, customWording) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'integrations.gumroad.customWording': customWording });
};

dao.userProfile.emailCampaignsSettings = {};

dao.userProfile.emailCampaignsSettings.togglePublishFailureEmail = (userProfile, val) => {
  const emailCampaign = 'publish_failure';
  if (val) {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayRemove(emailCampaign),
    });
  } else {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayUnion(emailCampaign),
    });
  }
};

dao.userProfile.emailCampaignsSettings.toggleWeeklyDigests = (userProfile, val) => {
  const emailCampaign = 'weekly_digest';
  if (val) {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayRemove(emailCampaign),
    });
  } else {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayUnion(emailCampaign),
    });
  }
};

dao.userProfile.emailCampaignsSettings.toggleDailyDigests = (userProfile, val) => {
  const emailCampaign = 'daily_digest';
  if (val) {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayRemove(emailCampaign),
    });
  } else {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayUnion(emailCampaign),
    });
  }
};

dao.userProfile.emailCampaignsSettings.toggleEmptyQueueEmails = (userProfile, val) => {
  const emailCampaign = 'empty_queue';
  if (val) {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayRemove(emailCampaign),
    });
  } else {
    return fb.usersCollection.doc(userProfile.uid).update({
      disabledEmailCampaigns: firebase.firestore.FieldValue.arrayUnion(emailCampaign),
    });
  }
};

dao.userProfile.toggleIsAnalyticsPagePublic = (userProfile, val) => {
  return fb.usersCollection.doc(userProfile.uid).update({
    isAnalyticsPagePublic: val,
  });
};

dao.userProfile.toggleLinkedInExpirationReminder = (userId, val) => {
  return fb.usersCollection.doc(userId).update({
    'settings.shouldSendLinkedInExpirationReminder': val,
  });
};

dao.recurrentPosts.createMultiple = (docs) => {
  const batch = fb.firebase.firestore().batch();
  docs.forEach(doc => {
    const newPostRef = fb.recurrentPostsCollection.doc();
    doc.created_at = firebase.firestore.FieldValue.serverTimestamp();
    doc.updated_at = firebase.firestore.FieldValue.serverTimestamp();
    batch.set(newPostRef, doc);
  })
  return batch.commit();
}

dao.recurrentPosts.update = (recurrentPostId, day, time, category = null) => {
  return fb.recurrentPostsCollection.doc(recurrentPostId)
    .update({
      ...(category ? { category: fb.categoriesCollection.doc(category) } : {}),
      day,
      time,
      updated_at: firebase.firestore.FieldValue.serverTimestamp()
    });
}

dao.recurrentPosts.delete = (recurrentPostId) => {
  return fb.recurrentPostsCollection.doc(recurrentPostId).delete();
}

dao.userProfile.disableBetaFeature = (userId, name) => {
  const featureEnabled = `settings.betaFeatures.${name}.enabled`;
  return fb.usersCollection.doc(userId).update({ [featureEnabled]: false });
};

dao.userProfile.enableBetaFeature = (userId, name) => {
  const featureEnabled = `settings.betaFeatures.${name}.enabled`;
  return fb.usersCollection.doc(userId).update({ [featureEnabled]: true });
};

dao.userProfile.updateAccountsToRetweet = (userId, accountsToBeRetweeted) => {
  return fb.usersCollection.doc(userId).update({ accountsToBeRetweeted });
};

dao.userProfile.enableAutoRT = (userId) => {
  return fb.usersCollection.doc(userId).update({ autoRT: true });
};

dao.userProfile.addAdditionalTwitterAccount = (userId, accessToken, accessTokenSecret, profileInfo) => {
  const userInfo = {
    accessToken,
    accessTokenSecret,
    profileInfo,
  };
  return fb.usersCollection
    .doc(userId)
    .update({ additionalAccounts: firebase.firestore.FieldValue.arrayUnion(userInfo) });
};

dao.userProfile.updateFeaturesToRemindOfDate = (userId, feature) => {
  const FEATURES = [
    'instagramInactivity',
    'linkedInInactivity',
    'facebookInactivity',
    'gumroadSalesInactivity',
    'instagramNotConnected',
    'linkedInNotConnected',
    'facebookNotConnected',
    'gumroadNotConnected',
    'evergreenRetweetsNotEnabled',
    'autoplugNotEnabled',
    'autoRetweetsNotEnabled',
    'followHypefuryOnTwitter',
    'addAccountManager',
    'pinTabToBrowser',
    'visitMarketplace',
    'enableNotifications',
    'promoteHypefury',
    'upgradeToYearly',
    'highAutoplugPostingPercent'
  ];

  if (!FEATURES.includes(feature)) {
    throw new Error('Feature not supported.');
  }

  const featureToRemindOf = `featuresToRemindOf.${feature}.lastTimeDisplayedAt`;
  return fb.usersCollection.doc(userId).update({ [featureToRemindOf]: new Date() });
};

dao.userProfile.updateFocusModeDate = (userId) => {
  return fb.usersCollection
    .doc(userId)
    .update({ ['focusMode.lastTimeUsedAt']: new Date() });
};

dao.userProfile.updateFeedSettings = (userId, feedSettings) => {
  return fb.usersCollection.doc(userId).update({ ...feedSettings });
};

dao.userProfile.hideTweet = (userId, tweetId, isSearchFeed = false) => {
  return isSearchFeed
    ? fb.usersCollection.doc(userId).update({
        [`integrations.twitter.searchFeedInteractions.${tweetId}.isHidden`]: true,
      })
    : fb.usersCollection.doc(userId).update({
        [`integrations.twitter.feedInteractions.${tweetId}.isHidden`]: true,
      });
};

dao.userProfile.updateManagers = (userId, managers) => {
  return fb.usersCollection.doc(userId).update({ managers });
};

dao.saveThreadsInBatchesToFirestore = (threads) => {
  const batch = fb.firebase.firestore().batch();
  threads.forEach((thread) => {
    const ref = fb.threadsCollection.doc();
    batch.set(ref, thread);
  });
  return batch.commit();
};

dao.userProfile.updateAppLastUsedAt = (userId) => {
  return fb.usersCollection.doc(userId).update({ appLastUsedAt: new Date() });
};

dao.posts.getBestTweetsForAI = async (userId) => {
  const userRef = fb.usersCollection.doc(userId);
  const bestHypefuryTweets = await fb.threadsCollection
    .where('deleted', '==', false)
    .where('scheduled', '==', true)
    .where('type', '==', 'post')
    .where('publishingError', '==', null)
    .where('user', '==', userRef)
    .where('source', '==', 'hypefury-app')
    .orderBy('favoriteCountOfTheFirstTweet', 'desc')
    .limit(300)
    .get();

  const bestTweetsFromOtherSources = await fb.threadsCollection
    .where('deleted', '==', false)
    .where('scheduled', '==', true)
    .where('type', '==', 'post')
    .where('publishingError', '==', null)
    .where('user', '==', userRef)
    .where('source', 'in', ['csv', 'growth-program', 'twitter'])
    .orderBy('favoriteCountOfTheFirstTweet', 'desc')
    .limit(200)
    .get();

  const topTweets = [...bestHypefuryTweets.docs, ...bestTweetsFromOtherSources.docs].map((doc) => ({
    ...doc.data().tweets[0],
    id: doc.id,
    source: doc.data().source,
  }));

  const filteredTopTweets = topTweets.filter((tweet) => {
    const hasSomeText = tweet.status.length > 0;
    const hasNoHashTags = !tweet.status.match(/[#]+[A-Za-z0-9-_]+/g);
    const hasNoLinks = !tweet.status.match(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g);
    const hasNoMentions = !tweet.status.match(/[@]+[A-Za-z0-9-_]+/g);
    const hasNoMedia = lodash.isNil(tweet.media) || tweet.media.length === 0;
    const hasNoQuestions = !tweet.status.includes('?');
    const hasAtLeastOneLike = Number.isInteger(tweet.favoriteCount) && tweet.favoriteCount > 0;

    return (
      hasSomeText &&
      hasNoHashTags &&
      hasNoLinks &&
      hasNoMentions &&
      hasNoMedia &&
      hasNoQuestions &&
      hasAtLeastOneLike
    );
  });

  const bestTweets = [];

  // Pick 20 random tweets
  for (let i = 0; i <= 20; i++) {
    const randomTweet = filteredTopTweets[Math.floor(Math.random() * filteredTopTweets.length)];

    if (randomTweet && !bestTweets.find((tweet) => tweet.id === randomTweet.id)) {
      bestTweets.push(randomTweet);
    }
  }

  // Put Hypefury tweets on top
  const hypefuryTweets = bestTweets.filter((tweet) => tweet.source === 'hypefury-app');
  const sortedTweets = [
    ...hypefuryTweets,
    ...bestTweets.filter((tweet) => tweet.source !== 'hypefury-app'),
  ].sort((a, b) => b.favoriteCount - a.favoriteCount);

  return sortedTweets;
};

dao.posts.getPostClones = (postId) => {
  const postRef = fb.threadsCollection.doc(postId);
  return fb.threadsCollection.where('clonedFromPost', '==', postRef).get();
}

dao.userProfile.updateGhostwritingClientsData = (userId, clientId, fieldValue, fieldName) => {
  const fieldPath = `ghostwritingClientsData.${clientId}.${fieldName}`;
  const userRef = fb.usersCollection.doc(userId);

  return userRef.update({
    [fieldPath]: fieldValue,
  });
}

dao.userProfile.updateAutoShareOnInstagramConditions = (userId, instagramAutoShare) => {
  return fb.usersCollection.doc(userId).update({
    'settings.instagramAutoShare': instagramAutoShare,
  });
};

dao.userProfile.updateAutoShareOnFacebookConditions = (userId, facebookAutoShare) => {
  return fb.usersCollection.doc(userId).update({
    'settings.facebookAutoShare': facebookAutoShare,
  });
};

dao.userProfile.updateLinkedInAutoplugConditions = (userId, linkedInAutoplug) => {
  return fb.usersCollection.doc(userId).update({
    'settings.linkedInAutoplug': linkedInAutoplug,
  });
};

dao.posts.getPostedAutoplugs = (userId) => {
  return fb.threadsCollection
    .where('user', '==', fb.usersCollection.doc(userId))
    .where('deleted', '==', false)
    .where('scheduled', '==', true)
    .where('type', '==', 'post')
    .where('publishingError', '==', null)
    .where('autoplug.processed', '==', true)
    .where('autoplug.favoriteCount', '>=', 0)
    .get();
};

dao.posts.setServiceToRetry = (postId, service) => {
  return fb.threadsCollection.doc(postId).update({
    servicesToRetry: firebase.firestore.FieldValue.arrayUnion(service),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    ...(service === 'linkedIn'
      ? { linkedInPublishingError: null }
      : service === 'instagram'
      ? { instagramPublishingError: null }
      : { facebookPublishingError: null }),
  });
};

dao.userProfile.updateInstagramHashtags = (userId, hashtags) => {
  const userRef = fb.usersCollection.doc(userId);

  return userRef.update({
    instagramHashtags: hashtags,
  });
}

dao.userProfile.updateAutoUnretweeterSettings = (userId, settings) => {
  return fb.usersCollection.doc(userId).update({ 'settings.autoUnretweeterSettings': settings });
};

dao.userProfile.deleteExternalAppAPIKey = (userId, apiKey) => {
  return fb.usersCollection
    .doc(userId)
    .update({ externalAppsAPIKeys: firebase.firestore.FieldValue.arrayRemove(apiKey) });
};

dao.userProfile.getUsersWhoCancelled = () => {
  return fb.usersCollection
    .where('subscriptionEndDate', '!=', null)
    .orderBy('subscriptionEndDate', 'desc')
    .limit(100)
    .get();
};

dao.getAutoDMUserData = (userId) => {
  return fb.autoDMTwitterUsersCollection.doc(userId).get();
};

dao.userProfile.addAutoDMTag = (userId, tag) => {
  return fb.usersCollection.doc(userId).update({
    autoDMTags: firebase.firestore.FieldValue.arrayUnion(tag),
  });
};

dao.updateAutoDMTags = (campaignId, tags, isPrivateCampaign) => {
  const firestoreCollection = isPrivateCampaign ? fb.dmCampaignsCollection : fb.threadsCollection;
  return firestoreCollection.doc(campaignId).update({
    'autoDM.tags': tags,
  });
};

dao.disableCampaign = (campaignId) => {
  return fb.threadsCollection.doc(campaignId).update({
    'autoDM.isDisabled': true,
  });
};

dao.dmCampaign.createCampaign = (userId, campaignData) => {
  return fb.dmCampaignsCollection.add({
    user: fb.usersCollection.doc(userId),
    ...campaignData,
  });
};

dao.userProfile.toggleThreadFinisher = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({'settings.isThreadFinisherEnabled': value});
};

dao.appSettings.fetchCoupons = async () => {
  const doc = await fb.firestore
    .collection('appSettings')
    .doc('production')
    .get();

  return lodash.get(doc.data(), 'coupons', {});
};

dao.appSettings.fetchReelsAudio = async () => {
  const doc = await fb.firestore.collection('appSettings').doc('production').get();

  return lodash.get(doc.data(), 'reelsAudio', []);
};

dao.appSettings.fetchReelsVideo = async () => {
  const doc = await fb.firestore.collection('appSettings').doc('production').get();

  return lodash.get(doc.data(), 'reelsVideo', []);
};

dao.userProfile.toggleConditionalRetweets = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.conditionalRetweetsConditions.conditionalRetweetsEnabled': value });
};

dao.userProfile.toggleAutoplug = (userId, value) => {
  return fb.usersCollection
    .doc(userId)
    .update({ 'settings.autoplugConditions.autoplugEnabled': value });
};

dao.userProfile.toggleLinkedInAutoplug = (userId, value) => {
  return fb.usersCollection.doc(userId).update({
    'settings.linkedInAutoplug.enabled': value,
    ...(value ? { 'settings.linkedInAutoplug.lastTimeFeatureWasEnabled': new Date() } : {}),
  });
};

dao.userProfile.toggleAutoShareOnInstagram = (userId, value) => {
  return fb.usersCollection.doc(userId).update({
    'settings.instagramAutoShare.enabled': value,
    ...(value ? { 'settings.instagramAutoShare.lastTimeFeatureWasEnabled': new Date() } : {}),
  });
};

dao.userProfile.toggleAutoShareOnFacebook = (userId, value) => {
  return fb.usersCollection.doc(userId).update({
    'settings.facebookAutoShare.enabled': value,
    ...(value ? { 'settings.facebookAutoShare.lastTimeFeatureWasEnabled': new Date() } : {}),
  });
};

dao.appSettings.updateCoupon = (couponData) => {
  const couponPath = `coupons.${couponData.code}`;

  return fb.firestore
    .collection('appSettings')
    .doc('production')
    .update({
      [couponPath]: {
        durationInMonths: Number(couponData.durationInMonths),
        discountAmount: Number(couponData.discountAmount),
        discountType: couponData.discountType,
      },
    });
};

dao.appSettings.deleteCoupon = (couponCode) => {
  const couponPath = `coupons.${couponCode}`;

  return fb.firestore
    .collection('appSettings')
    .doc('production')
    .update({
      [couponPath]: firebase.firestore.FieldValue.delete(),
    });
}

dao.appSettings.getMentionsPageAllowedUsers = async () => {
  const appSettings = await fb.firestore
    .collection('appSettings')
    .doc('production')
    .get();

  return lodash.get(appSettings.data(), 'mentionsPageAllowedUsers', []);
}

dao.posts.updateCategories = (postId, categories) => {
  return fb.threadsCollection.doc(postId).update({
    categories,
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.userProfile.saveGeneratedQuote = (userId, quote) => {
  return fb.usersCollection.doc(userId).update({
    lastGeneratedQuote: quote,
  });
};

dao.posts.fetchLastAMAPost = (userId) => {
  return fb.threadsCollection
    .where('user', '==', fb.usersCollection.doc(userId))
    .where('ama.isEnabled', 'in', [false, true])
    .orderBy('created_at', 'desc')
    .limit(1)
    .get();
};

dao.amaReplies.fetchAMAReplies = (postId) => {
  return fb.amaRepliesCollection
    .where('didUserReply', '==', false)
    .where('isDismissed', '==', false)
    .where('amaThreadRef', '==', fb.threadsCollection.doc(postId))
    .orderBy('created_at', 'desc')
    .get();
};

dao.amaReplies.deleteAMAReplies = (amaReplyIds) => {
  const deletePromises = amaReplyIds.map((id) => {
    return fb.amaRepliesCollection.doc(id).update({
      isDismissed: true,
    });
  });
  return Promise.all(deletePromises);
};

dao.userProfile.updateGoogleAccessToken = (userId, accessToken, refreshToken, expiresIn) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.google.tokenInfo.accessToken': accessToken,
    'integrations.google.tokenInfo.createdAt': new Date(),
    'integrations.google.tokenInfo.expiresIn': expiresIn,
    ...(refreshToken ? { 'integrations.google.tokenInfo.refreshToken': refreshToken } : {}),
  });
};

dao.integrations.deleteYouTubeInformation = (userId) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.google': null,
    'integrations.youtube': null,
  });
};

dao.userProfile.setYouTubeChannel = (userId, channelId, channelName) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.youtube.channel.id': channelId,
    'integrations.youtube.channel.name': channelName,
  });
};

dao.youtubeVideos.saveVideo = (userId, { channelId, videoId, title }) => {
  const userRef = fb.usersCollection.doc(userId);
  return fb.youtubeVideosCollection.doc(videoId).set({
    path: `youtube-videos/${channelId}/${title}.mp4`,
    user: userRef,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

dao.youtubeVideos.getVideo = (videoId) => {
  return fb.youtubeVideosCollection.doc(videoId).get();
};

dao.youtubeShorts.getShort = (shortId) => {
  return fb.youtubeShortsCollection.doc(shortId).get();
};

dao.integrations.deleteThreadsInformation = (userId) => {
  return fb.usersCollection.doc(userId).update({
    'integrations.threads': null,
  });
};

export default dao;
