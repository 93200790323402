<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isLongFormTweetMode() {
        return this.userProfile.customerStatus !== 'none' && this.userProfile.isTwitterVerified;
      },
      // This is related to tweetLength in csv-uploads-firestore-triggers.js, changes here should be reflected there
      tweetCharactersLimit() {
        return this.isLongFormTweetMode ? 4000 : 280;
      },
    },
  };
</script>
