import { isNil } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { ScheduleForSettings } from "./ScheduleForSettings";

class UserProfile {
  created_at;
  email;
  growthProgram;
  growthProgramName;
  growthProgramsRoles;
  name;
  photoURL;
  providedName;
  schedule;
  #scheduleRaw;
  twitterAccessTokenKey;
  twitterAccessTokenSecret;
  twitterId;
  uid;
  username;
  autoRT;
  role;
  subscriptionCustomerId;
  subscriptionFirstDate;
  subscriptionEndDate;
  subscriptionInterval;
  subscriptionStatus;
  subscriptionTierName;
  followersCount;
  followingCount;
  followersDiffSinceLastWeek;
  settings;
  trialStartsAt;
  conditionalRetweetsConditions;
  autoplugConditions;
  integrations;
  totalScheduledPosts;
  totalScheduledRetweets;
  isAdmin;
  currentlyScheduledPostsCount;
  queueLastUpdatedAt;
  delayBetweenTweetsConditions;
  tweetMetricsUpdatedAt;
  onboardingChecklist;
  disabledEmailCampaigns;
  accountsToBeRetweeted;
  isAnalyticsPagePublic;
  additionalAccounts;
  additionalAccountIds;
  parentsCustomerStatus;
  customerStatus;
  trialEndsAt;
  autoplugTemplates;
  managerInvitationTokens;
  managers;
  managedAccounts;
  featuresToRemindOf;
  focusMode;
  followersActivityData;
  notifications;
  isBeingDeleted;
  wasFirstGumroadSaleScheduled;
  appLastUsedAt;
  tweetsLastFetchedAt;
  feedSettings;
  aiCallsCounter;
  totalScheduledAIPosts;
  ghostwritingClientsData;
  isGhostwriter;
  ghostwriterName;
  subscriptionResumesAt;
  draftPostCount;
  instagramHashtags;
  externalAppsAPIKeys;
  externalApps;
  tweetingStreak;
  lastPostPublishedFromHypefuryAt;
  autoDMTags;
  inspirationTweetsLastIndexedAt;
  freeWeeklyAutoDMs;
  newUIAlert;
  hasSignedUpFromMobile;
  twitterAccessTokenValidity;
  reels;
  shouldForceOldLimits;
  constructor(user) {
    this.created_at = user.created_at;
    this.email = user.email;
    this.name = user.name;
    this.growthProgram = user.growthProgram;
    this.growthProgramName = user.growthProgramName;
    this.growthProgramsRoles = user.growthProgramsRoles;
    this.photoURL = user.photoURL;
    this.providedName = user.providedName;
    this.#scheduleRaw = user.schedule;
    this.schedule = new ScheduleForSettings(this.#scheduleRaw);
    this.twitterAccessTokenKey = user.twitterAccessTokenKey;
    this.twitterAccessTokenSecret = user.twitterAccessTokenSecret;
    this.twitterId = user.twitterId;
    this.uid = user.uid;
    this.username = user.username;
    this.timezone = user.timezone;
    this.autoRT = !isNil(user.autoRT) ? user.autoRT : true;
    this.role = user.role;
    this.subscriptionCustomerId = user.subscriptionCustomerId;
    this.subscriptionFirstDate = user.subscriptionFirstDate;
    this.subscriptionEndDate = user.subscriptionEndDate;
    this.subscriptionInterval = user.subscriptionInterval;
    this.subscriptionTierName = user.subscriptionTierName;
    this.subscriptionStatus = user.subscriptionStatus;
    this.subscriptionId = user.subscriptionId;
    this.followersCount = user.followersCount;
    this.followingCount = user.followingCount;
    this.followersDiffSinceLastWeek = user.followersDiffSinceLastWeek;
    this.settings = user.settings;
    this.settings.shouldAutoFillEmptyComposer = isNil(user.settings.shouldAutoFillEmptyComposer) ?  true : user.settings.shouldAutoFillEmptyComposer;
    this.settings.showInspirationTweetsInComposer = isNil(user.settings.showInspirationTweetsInComposer) ?  false : user.settings.showInspirationTweetsInComposer;
    this.settings.isShareOnInstagramEnabled = isNil(user.settings.isShareOnInstagramEnabled) ?  false : user.settings.isShareOnInstagramEnabled;
    this.settings.inspirationTweetsType = isNil(user.settings.inspirationTweetsType) ?  'writersblock' : user.settings.inspirationTweetsType;
    this.settings.showOnlyFilledHours = isNil(user.settings.showOnlyFilledHours) ? false : user.settings.showOnlyFilledHours;
    this.settings.theme = user.settings.theme || 'dark';
    this.settings.isThreadFinisherEnabled = isNil(user.settings.isThreadFinisherEnabled) ? false : user.settings.isThreadFinisherEnabled;
    this.settings.isShareOnFacebookEnabled = isNil(user.settings.isShareOnFacebookEnabled) ? false : user.settings.isShareOnFacebookEnabled;
    this.settings.isShareOnLinkedInEnabled = isNil(user.settings.isShareOnLinkedInEnabled) ? false : user.settings.isShareOnLinkedInEnabled;
    this.trialStartsAt = user.trialStartsAt ? moment(user.trialStartsAt.toDate()) : null;
    this.conditionalRetweetsConditions = user.conditionalRetweetsConditions;
    this.autoplugConditions = user.autoplugConditions;
    this.integrations = user.integrations ? user.integrations : {};
    this.totalScheduledPosts = user.totalScheduledPosts ? user.totalScheduledPosts : null;
    this.totalScheduledRetweets = user.totalScheduledRetweets ? user.totalScheduledRetweets : null;
    this.isAdmin = user.isAdmin;
    this.currentlyScheduledPostsCount = isNil(user.currentlyScheduledPostsCount) ? null : user.currentlyScheduledPostsCount;
    this.queueLastUpdatedAt = user.queueLastUpdatedAt ? user.queueLastUpdatedAt : null;
    this.delayBetweenTweetsConditions = user.delayBetweenTweetsConditions;
    this.tweetMetricsUpdatedAt = user.tweetMetricsUpdatedAt ? user.tweetMetricsUpdatedAt : null;
    this.onboardingChecklist = user.onboardingChecklist;
    this.disabledEmailCampaigns = user.disabledEmailCampaigns ? user.disabledEmailCampaigns : [];
    this.emailNotificationsSettings = {
      isPublishFailureEmailDisabled: this.disabledEmailCampaigns.includes('publish_failure'),
      isWeeklyDigestDisabled: this.disabledEmailCampaigns.includes('weekly_digest'),
      isDailyDigestDisabled: this.disabledEmailCampaigns.includes('daily_digest'),
      isEmptyQueueEmailDisabled: this.disabledEmailCampaigns.includes('empty_queue'),
    };
    this.accountsToBeRetweeted = user.accountsToBeRetweeted ? user.accountsToBeRetweeted : [];
    this.isAnalyticsPagePublic = user.isAnalyticsPagePublic ? user.isAnalyticsPagePublic : false;
    this.additionalAccounts = user.additionalAccounts ? user.additionalAccounts : [];
    this.additionalAccountIds = user.additionalAccountIds ? user.additionalAccountIds : [];
    this.parentsCustomerStatus = user.parentsCustomerStatus ? user.parentsCustomerStatus : {};
    this.customerStatus = user.customerStatus ? user.customerStatus : 'none';
    this.trialEndsAt = user.trialEndsAt ? user.trialEndsAt : null;
    this.autoplugTemplates = user.autoplugTemplates || [];
    this.managerInvitationTokens = user.managerInvitationTokens || [];
    this.managers = user.managers ? user.managers : [];
    this.managedAccounts = user.additionalAccounts
      ? user.additionalAccounts.filter((account) => account.type === 'managed')
      : [];
    this.featuresToRemindOf = user.featuresToRemindOf;
    this.focusMode = user.focusMode;
    this.followersActivityData = user.followersActivityData;
    this.notifications = user.notifications;
    this.isBeingDeleted = Boolean(user.isBeingDeleted);
    this.wasFirstGumroadSaleScheduled = user.wasFirstGumroadSaleScheduled;
    this.appLastUsedAt = user.appLastUsedAt || null;
    this.tweetsLastFetchedAt = user.tweetsLastFetchedAt;
    this.feedSettings = user.feedSettings ? user.feedSettings : null;
    this.aiCallsCounter = Number.isInteger(user.aiCallsCounter) ? user.aiCallsCounter : 0;
    this.totalScheduledAIPosts = Number.isInteger(user.totalScheduledAIPosts) ? user.totalScheduledAIPosts : 0;
    this.lastAIPostsResetDate = user.lastAIPostsResetDate ? user.lastAIPostsResetDate : null;
    this.ghostwritingClientsData = user.ghostwritingClientsData ? user.ghostwritingClientsData : {};
    this.isGhostwriter = user.isGhostwriter ? user.isGhostwriter : false;
    this.ghostwriterName = user.ghostwriterName ? user.ghostwriterName : null;
    this.subscriptionResumesAt = user.subscriptionResumesAt ? user.subscriptionResumesAt : null;
    this.draftPostCount = user.draftPostCount ? user.draftPostCount : null;
    this.instagramHashtags = user.instagramHashtags || user.instagramHashtags === '' ? user.instagramHashtags : null;
    this.externalAppsAPIKeys = user.externalAppsAPIKeys ? user.externalAppsAPIKeys : [];
    this.externalApps = user.externalApps ? user.externalApps : {};
    this.tweetingStreak = user.tweetingStreak || 0;
    this.lastPostPublishedFromHypefuryAt = user.lastPostPublishedFromHypefuryAt || null;
    this.autoDMTags = user.autoDMTags || [];
    this.isStripeCustomer = Boolean(user.isStripeCustomer);
    this.inspirationTweetsLastIndexedAt = user.inspirationTweetsLastIndexedAt || false;
    this.source = user.source || null;
    this.autoDMDailyCounter = user.autoDMDailyCounter || {
      successfulDMs: 0,
      failedDMs: 0,
      updatedAt: null,
    };
    this.freeWeeklyAutoDMs = user.freeWeeklyAutoDMs || null;
    this.newUIAlert = user.newUIAlert || null;
    this.hasSignedUpFromMobile = user.hasSignedUpFromMobile || false;
    this.twitterAccessTokenValidity = user.twitterAccessTokenValidity || {
      areTokensValid: true,
      lastUpdatedAt: new Date(),
    };
    this.reels = user.reels || {
      lastUpdatedAt: null,
      renderCount: 0,
    };
    this.isTwitterVerified = user.isTwitterVerified;
    this.shouldForceOldLimits = user.shouldForceOldLimits || false;
  }

  createdAt() {
    // I don't think we need to use moment-tz as the date itself already
    // contains timezone information - But I will leave it like this for now...
    const createdAt = (this.created_at) ? this.created_at.toDate() : new Date();
    return moment.tz(createdAt, this.timezone);
  }

  isSecondaryAccount() {
    return (
      typeof this.parentsCustomerStatus === 'object' &&
      Object.keys(this.parentsCustomerStatus).length > 0
    );
  }
}

export { UserProfile };
